import React from 'react';

import { Link } from 'gatsby';
import { Image } from 'react-bootstrap';
import './index.scss';

const PerksCompanies = ({ companies }) => {
  const getCategoryIcon = ({ company }) => {
    if (company.newCategory === 'Practice Self-Care') {
      return (
        <Image
          src="../images/self-love.png"
          alt="Practice Self-Care"
          className="category-icon"
        />
      );
    } else if (company.newCategory === 'Treat Yourself') {
      return (
        <Image
          src="../images/treat-yourself.png"
          alt="Treat Yourself"
          className="category-icon"
        />
      );
    } else if (company.newCategory === 'Level Up') {
      return (
        <Image
          src="../images/level-up.png"
          alt="Level Up"
          className="category-icon"
        />
      );
    }
  };

  return (
    <div className="perks-companies d-flex flex-row justify-content-around">
      {companies.map(company => (
        <div
          className="perk-company d-flex flex-column justify-content-between"
          key={company.name}
        >
          <div className="company-info d-flex flex-column justify-content-center align-items-center">
            <h6>{company.name}</h6>
            <p>{company.shortDescription}</p>
            {company.discount && (
              <p className="emphasized">{company.discount}</p>
            )}
            {company.averageSavings && (
              <p className="emphasized">
                Average savings: {company.averageSavings}
              </p>
            )}
          </div>
          <Image
            src={company.image.url}
            alt={company.image.title}
            className="company-image"
          />
          <div className="d-flex flex-row icon-row">
            <div>{getCategoryIcon({ company })}</div>
            <Link
              to={`/perks/${company.founder.slug}`}
              key={company.founder.slug}
              className="open-up-arrow"
              name="open details"
            >
              <Image src="../images/open-up-right.svg" alt="open arrow" />
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PerksCompanies;
