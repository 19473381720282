import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import { get } from 'lodash';

import PerksCompanies from '../../components/PerksCompanies';
import RewardsHero from '../../components/Rewards/RewardsHero';
import RewardsPinkTax from '../../components/Rewards/RewardsPinkTax';

function PerksPage() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulPerksPage {
        nodes {
          heroHeader
          heroSubheader
          heroButtonText
          heroBackgroundColor
          pinkTaxHeader
          pinkTaxContent {
            childMarkdownRemark {
              html
            }
          }
          pinkTaxButtonText
          pinkTaxUrl
          partnerCompany {
            category
            newCategory
            name
            discount
            averageSavings
            image {
              title
              url
            }
            logo {
              title
              url
            }
            shortDescription
            founder {
              name
              title
              slug
              founderImage {
                title
                url
              }
              description {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }
      }
    }
  `);

  const content = get(data, 'allContentfulPerksPage.nodes[0]', []);

  return (
    <main>
      <RewardsHero
        header={content.heroHeader}
        subheader={content.heroSubheader}
        backgroundColor={content.heroBackgroundColor}
        buttonText={content.heroButtonText}
      />
      <PerksCompanies companies={content.partnerCompany} />
      <RewardsPinkTax
        header={content.pinkTaxHeader}
        content={content.pinkTaxContent}
        buttonText={content.pinkTaxButtonText}
        url={content.pinkTaxUrl}
      />
    </main>
  );
}

export default PerksPage;
