import React, { useState, useEffect } from 'react';

import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';

import FlodeskModal from '../../Flodesk/SignupModal';

import './index.scss';

const RewardsHero = ({ header, backgroundColor, subheader, buttonText }) => {
  const [modalShow, setModalShow] = useState(false);
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  return (
    hasMounted && (
      <div
        className="rewards__hero"
        style={{ backgroundColor: backgroundColor }}
      >
        <h1>{header}</h1>
        <p>{subheader}</p>
        <div className="signup__button">
          <Link
            to="/perks"
            className="purple-button"
            onClick={() => setModalShow(true)}
          >
            {buttonText}
          </Link>
        </div>
        <div className="category-key__container">
          <p>
            Sequin is proud to offer our members perks to help you do the
            following:
          </p>
          <div className="category-key d-flex flex-row">
            <div className="category-key__item d-flex flex-column">
              <Image
                src="../images/self-love.png"
                role="img"
                alt="woman hugging herself"
              />
              <p>
                Practice <br />
                Self-Care
              </p>
            </div>
            <div className="category-key__item d-flex flex-column">
              <Image
                src="../images/treat-yourself.png"
                role="img"
                alt="gift with hearts"
              />
              <p>Treat Yourself</p>
            </div>
            <div className="category-key__item d-flex flex-column">
              <Image
                src="../images/level-up.png"
                role="img"
                alt="arrow pointing up"
              />
              <p>Level Up</p>
            </div>
          </div>
        </div>
        <FlodeskModal show={modalShow} onHide={() => setModalShow(false)} />
      </div>
    )
  );
};

RewardsHero.propTypes = {
  backgroundColor: PropTypes.string,
  buttonText: PropTypes.string,
  header: PropTypes.string,
  subheader: PropTypes.string
};

export default RewardsHero;
