import React from 'react';

import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import './index.scss';

const RewardsPinkTax = ({ header, content, buttonText, url }) => {
  return (
    <div className="rewardsPinkTax">
      <div className="site-wrap d-flex flex-column justify-content-center align-items-center">
        <div className="rewardsPinkTax__details d-flex flex-column justify-content-center align-items-center">
          <h2>{header}</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: content?.childMarkdownRemark?.html
            }}
            className="mb-5 text-center"
          />
          <Link to={url} className="purple-button">
            {buttonText}
          </Link>
        </div>
      </div>
    </div>
  );
};

RewardsPinkTax.propTypes = {
  buttonText: PropTypes.string,
  content: PropTypes.shape({
    childMarkdownRemark: PropTypes.shape({
      html: PropTypes.string
    })
  }),
  header: PropTypes.string,
  url: PropTypes.string
};

export default RewardsPinkTax;
